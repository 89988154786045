import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import ImageCallout from '../willoughby/image-callout'

export default function ImageCalloutOne(props) {
  const da = useStaticQuery(graphql`
  {
    cropped: allFile(
      filter: {absolutePath: {regex: "/year-in-review/2021/cropped/callouts/"}, ext: {eq: ".jpg"}}
      sort: {fields: name, order: ASC}
    ) {
      nodes {
        publicURL
        name
        childImageSharp {
          gatsbyImageData(
            height: 300
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
    fullsize: allFile(
      filter: {absolutePath: {regex: "/year-in-review/2022/uncropped/callouts/"}, ext: {eq: ".jpg"}}
      sort: {fields: name, order: ASC}
    ) {
      nodes {
        name
        publicURL
        childImageSharp {
          gatsbyImageData(
            width: 1600
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }`)


  return (
    <ImageCallout content={props.content} data={da} />
  )
}
