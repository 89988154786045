import React, { useState } from 'react'
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import SimpleLightbox from '../willoughby/lightbox';

export default function UnbalancedImageGrid2021() {

  // hardcoded constants
  const caption_one = "Annika and Alexa";
  const caption_two = "Thanks everyone for coming";

  // query for image data
  const data = useStaticQuery(graphql`
  {
    cropped: allFile(
      filter: {absolutePath: {regex: "/year-in-review/2021/cropped/unbalanced/"}, ext: {eq: ".jpg"}}
      sort: {fields: name, order: ASC}
    ) {
      nodes {
        publicURL
        name
        childImageSharp {
          gatsbyImageData(
            width: 600
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
    fullsize: allFile(
      filter: {absolutePath: {regex: "/year-in-review/2021/uncropped/unbalanced/"}, ext: {eq: ".jpg"}}
      sort: {fields: name, order: ASC}
    ) {
      nodes {
        name
        publicURL
        childImageSharp {
          gatsbyImageData(
            width: 1200
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }`)

  // Lightbox Setup
  const [isOpen, setOpen] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);
  function showLightbox(index, e) {
    setCurrentIndex(index);  // method for determining the index
    setOpen(true);
  }
  const lightboxImages = [
    {
      src: getSrc(data.fullsize.nodes.filter(fullphoto => fullphoto.name === 'baby_shower_1')[0]),
      alt: caption_one,
      caption: caption_one
    },
    {
      src: getSrc(data.fullsize.nodes.filter(fullphoto => fullphoto.name === 'baby_shower_2')[0]),
      alt: caption_two,
      caption: caption_two
    }
  ];

  return (
      <div className='image-container' >
        <div className='fw-33' style={{flexBasis: '30%' }} onClick={(e) => showLightbox(0, e)} >
          <GatsbyImage image={data.cropped.nodes.filter(fullphoto => fullphoto.name === 'baby_shower_1')[0].childImageSharp.gatsbyImageData} alt={caption_one} />
        </div>
        <div className='fw-50' style={{flexBasis: '63.14%' }} onClick={(e) => showLightbox(1, e)} >
            <GatsbyImage image={data.cropped.nodes.filter(fullphoto => fullphoto.name === 'baby_shower_2')[0].childImageSharp.gatsbyImageData} alt={caption_two} />
        </div>
        <SimpleLightbox
          currentImageIndex={currentImageIndex}
          setCurrentIndex={setCurrentIndex}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          images={lightboxImages}
        />
      </div>
  )
}