/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ImageGridOne from '../components/image-grid-2021';
import ImageCalloutOne from '../components/image-callout-2021';
import UnbalancedImageGridOne from '../components/unbalanced-image-grid-2021';
import BreadImage from '../components/bread-image';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "2021 Year in Review"), "\n", React.createElement(_components.p, null, "Hello, family and friends! We hope everyone is doing well."), "\n", React.createElement(_components.p, null, "This is it. We’ve become those people, the ones who are organised enough to compile a year in review newsletter. And why not start in 2021, an incredibly exciting year where we travelled almost nowhere and continued to embrace pandemic life in San Francisco?"), "\n", React.createElement(_components.p, null, "It feels reasonable to start with the biggest news first (also truly the only news of real note for us in 2021). Avery was born on June 24, almost right on schedule, after an uncomplicated and fairly quick 12 hour labour. The only small hiccup was getting diverted to a different hospital across town because our hospital was full (apparently due to the full moon..!), but it all worked out in the end (other than terrible hospital food, thank goodness for Uber Eats &#x1F600)."), "\n", React.createElement(ImageGridOne, {
    images_per_row: 4,
    aspect_ratio: 1,
    content: [{
      'name': 'avery_baby_01',
      'description': "Meeting Avery for the first time"
    }, {
      'name': 'avery_baby_02',
      'description': "Avery at 1 day old"
    }, {
      'name': 'avery_baby_03',
      'description': ""
    }, {
      'name': 'avery_baby_04',
      'description': "We made it home!"
    }, {
      'name': 'avery_baby_05',
      'description': "Wearing a sweater knitted by Nana"
    }, {
      'name': 'avery_baby_06',
      'description': "Three months old!"
    }, {
      'name': 'avery_baby_07',
      'description': "Six months"
    }, {
      'name': 'avery_baby_08',
      'description': "A Christmas snack"
    }]
  }), "\n", React.createElement(_components.p, null, "Her parents are very smitten with their chubby cheeked little gal. Her big cat sister, Kiki, doesn’t yet feel the same way, but Annika is confident that in time they will become BEST FRIENDS. Now that Avery is bigger, she is extremely interested in Kiki and would like nothing more than to caress and tug at Kiki’s fur to her heart’s content. Kiki doesn't reciprocate this feeling, but we still have lots of evidence of their relatively peaceful co-existence."), "\n", React.createElement(ImageGridOne, {
    images_per_row: 4,
    aspect_ratio: 1,
    content: [{
      'name': 'avery_kiki_01',
      'description': "Kiki crashing feeding time"
    }, {
      'name': 'avery_kiki_02',
      'description': "Avery and Kiki sharing Auntie Evelyn's lap"
    }, {
      'name': 'avery_kiki_04',
      'description': "Avery and Kiki overloading Andrew"
    }, {
      'name': 'avery_kiki_05',
      'description': "Joint tummy time"
    }, {
      'name': 'avery_kiki_06',
      'description': "Joint nap time"
    }, {
      'name': 'avery_kiki_07',
      'description': "Photo time!"
    }, {
      'name': 'avery_kiki_08',
      'description': "Distracting Andrew together"
    }, {
      'name': 'avery_kiki_09',
      'description': "More tummy time"
    }, {
      'name': 'avery_kiki_10',
      'description': "Kiki overseeing Avery's explorations with solids"
    }, {
      'name': 'avery_kiki_11',
      'description': "Kiki isn't so sure now that Avery can reach out at her"
    }, {
      'name': 'avery_kiki_12',
      'description': "Friends or enemies?"
    }]
  }), "\n", React.createElement(_components.p, null, "Shockingly, we do have some non-baby related updates from the first half of the year, so we’ll proceed with those before getting to further baby and cat spam."), "\n", React.createElement(BreadImage), "\n", React.createElement(_components.p, null, "Andrew has continued his sourdough bread baking, although sadly it’s taken a bit of a hit recently. We experimented with grinding our own wheat berries to make whole wheat bread which turned out to be far more effort than it was worth."), "\n", React.createElement(_components.p, null, "He continues his work at Uber as a Product Manager, but moved into a different team which has been a fun new challenge. He even managed to go into the brand new office a couple of times, which is only a 15 minute walk from our apartment. Sadly it opened during the pandemic so hasn’t gotten a lot of use yet!"), "\n", React.createElement(_components.p, null, "Annika was busy growing a human for the first six months of the year, but found plenty of time to let Kiki take advantage of her expanding belly."), "\n", React.createElement(ImageGridOne, {
    images_per_row: 3,
    aspect_ratio: 1,
    content: [{
      'name': 'kiki_on_belly_1',
      'description': ""
    }, {
      'name': 'kiki_on_belly_2',
      'description': ""
    }, {
      'name': 'kiki_on_belly_3',
      'description': ""
    }]
  }), "\n", React.createElement(_components.p, null, "She continues to work in Business Operations at Viz.ai, which has been a fun journey as the company continues to grow rapidly. She finally got to meet a few of her colleagues in person at some outdoor happy hours, but will be working remotely for the foreseeable future."), "\n", React.createElement(_components.p, null, "Early in the year, we fit in some hikes and trail runs with friends. We feel very lucky to live somewhere that has such amazing scenery so close to the city."), "\n", React.createElement(ImageGridOne, {
    images_per_row: 3,
    aspect_ratio: 2,
    content: [{
      'name': 'headlands_1',
      'description': "The Marin headlands, north of SF"
    }, {
      'name': 'headlands_2',
      'description': "Hiking above Pacifica, just south of SF"
    }, {
      'name': 'headlands_3',
      'description': "SF city through the Marin headlands"
    }]
  }), "\n", React.createElement(_components.p, null, "In March, we spent a long weekend in Tahoe with a couple of close friends. It was a long overdue opportunity to finally get out of the apartment and enjoy some fresh lake air!"), "\n", React.createElement(ImageGridOne, {
    images_per_row: 2,
    aspect_ratio: 1.5,
    content: [{
      'name': 'tahoe_1',
      'description': "Hanging out on the (cold) lake"
    }, {
      'name': 'tahoe_2',
      'description': "Nevada through snowy trees"
    }]
  }), "\n", React.createElement(_components.p, null, "In April, we took a “babymoon” to Mendocino, a few hours drive north of San Francisco. It was very relaxing and peaceful. We managed to fit in some kayaking, trail biking and hiking despite Annika being 30 weeks pregnant (none were done speedily)."), "\n", React.createElement(ImageGridOne, {
    images_per_row: 3,
    aspect_ratio: 1.33,
    content: [{
      'name': 'mendocino_1',
      'description': "Kayaking on the river"
    }, {
      'name': 'mendocino_2',
      'description': "The beach around sunset"
    }, {
      'name': 'mendocino_3',
      'description': "We took an easy hike to this waterfall"
    }]
  }), "\n", React.createElement(_components.p, null, "In May, Annika and her friend Alexa (mother of future BFF Stevie!) enjoyed a joint baby shower organised by some lovely friends of theirs. It was a classic cool, foggy SF day, but it was the first unmasked outdoor event any of us had attended since the start of the pandemic so it was a joyous occasion!"), "\n", React.createElement(UnbalancedImageGridOne), "\n", React.createElement(_components.p, null, "And since June, well…life has been pretty busy with keeping the babe alive. We did also move apartments in October, which was perhaps slightly foolhardy with a baby but has paid dividends with much more space to enjoy (and perhaps more importantly, both morning & afternoon sun for Kiki, as well as a balcony for her to enjoy some fresh air)."), "\n", React.createElement(_components.p, null, "That’s it! We've tried to be reasonable with the baby and cat photos, but it was a real effort (on Annika's behalf, at least). If you're interested, there are more photos of Avery and Kiki after the sign-off."), "\n", React.createElement(_components.p, null, "We sincerely hope 2022 brings a more exciting newsletter (although it will still have an awful lot of Avery and Kiki content), and more importantly brings everyone some more normalcy. We wish you all the very best for the year ahead."), "\n", React.createElement(_components.p, null, "Much love from the Lees-Craig household,"), "\n", React.createElement(_components.p, null, "Annika, Andrew, Avery and Kiki"), "\n", React.createElement(_components.h3, null, "Highlights from Avery's first 6 months"), "\n", React.createElement(ImageCalloutOne, {
    content: [{
      'id': 1,
      'text': "Meeting her future BFF, Stevie, although they didn’t acknowledge each other’s existence for several months",
      'images': [{
        'name': 'bffs_1',
        'description': "Avery meeting Stevie for the first time"
      }, {
        'name': 'bffs_2',
        'description': "Avery and Stevie acknowledging each other for the first time"
      }]
    }, {
      'id': 2,
      'text': "Meeting her namesake, Annika’s friend Avery",
      'images': [{
        'name': 'meeting_avery',
        'description': "Avery, meet Avery"
      }]
    }, {
      'id': 3,
      'text': "Meeting her Aunt Evelyn and Uncle Ian",
      'images': [{
        'name': 'ian_evelyn_1',
        'description': "Reading with her Aunt and Uncle"
      }, {
        'name': 'ian_evelyn_2',
        'description': "Family portrait time"
      }]
    }, {
      'id': 4,
      'text': "Celebrating her first Halloween as a cat (naturally)",
      'images': [{
        'name': 'halloween_1',
        'description': "Avery's halloween costume"
      }, {
        'name': 'halloween_2',
        'description': "Unfortunately no matching family outfits"
      }]
    }, {
      'id': 5,
      'text': "Groping many babies’ faces (she doesn't know how to play it cool)",
      'images': [{
        'name': 'babies_1',
        'description': "Avery and Luke"
      }, {
        'name': 'babies_3',
        'description': "Avery and Hawkins"
      }, {
        'name': 'babies_2',
        'description': "Avery and Stevie"
      }]
    }, {
      'id': 6,
      'text': "Developing a taste for the finer things in life",
      'images': [{
        'name': 'beverages_1',
        'description': "Attempting to try the wine at Thanksgiving"
      }, {
        'name': 'beverages_2',
        'description': "Going for Dad's beer"
      }]
    }, {
      'id': 7,
      'text': "Starting solids with much relish",
      'images': [{
        'name': 'solids_1',
        'description': "Trying yogurt for the first time"
      }, {
        'name': 'solids_2',
        'description': "Enjoying Avocado"
      }]
    }, {
      'id': 8,
      'text': "Taking her first plane trip (to visit some friends in Minnesota, where this very newsletter is being written from)",
      'images': [{
        'name': 'plane_1',
        'description': "Avery was pretty well behaved on the flight"
      }, {
        'name': 'plane_2',
        'description': "Meeting Lucy for the first time"
      }]
    }, {
      'id': 9,
      'text': "Enjoying her first snow in the frozen tundra of Minnesota",
      'images': [{
        'name': 'snow_1',
        'description': "Enjoying her first snow in the frozen tundra of Minnesota"
      }, {
        'name': 'snow_2',
        'description': "Walking on a lake in the snow"
      }]
    }]
  }), "\n", React.createElement(_components.h3, null, "Homage to Kiki"), "\n", React.createElement(_components.p, null, "Of course this wouldn’t be complete without paying homage to our first child, Kiki, who is still dearly beloved and very spoiled."), "\n", React.createElement(ImageGridOne, {
    images_per_row: 3,
    aspect_ratio: 1,
    content: [{
      'name': 'kiki_h_01',
      'description': ""
    }, {
      'name': 'kiki_h_02',
      'description': ""
    }, {
      'name': 'kiki_h_03',
      'description': ""
    }, {
      'name': 'kiki_h_04',
      'description': ""
    }, {
      'name': 'kiki_h_05',
      'description': ""
    }, {
      'name': 'kiki_h_06',
      'description': ""
    }, {
      'name': 'kiki_h_07',
      'description': ""
    }, {
      'name': 'kiki_h_08',
      'description': ""
    }, {
      'name': 'kiki_h_10',
      'description': ""
    }, {
      'name': 'kiki_h_11',
      'description': ""
    }, {
      'name': 'kiki_h_12',
      'description': ""
    }]
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
