import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import ImageGrid from '../willoughby/image-grid'

export default function ImageGridOne(props) {
  const data = useStaticQuery(graphql`
  {
    cropped: allFile(
      filter: {absolutePath: {regex: "/year-in-review/2021/cropped/grids/"}, ext: {eq: ".jpg"}}
      sort: {fields: name, order: ASC}
    ) {
      nodes {
        publicURL
        name
        childImageSharp {
          gatsbyImageData(
            height: 400
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
    fullsize: allFile(
      filter: {absolutePath: {regex: "/year-in-review/2021/uncropped/grids/"}, ext: {eq: ".jpg"}}
      sort: {fields: name, order: ASC}
    ) {
      nodes {
        name
        publicURL
        childImageSharp {
          gatsbyImageData(
            width: 1600
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }`)

  return (
    <ImageGrid content={props.content} imagesPerRow={props.images_per_row} queryData={data}/>
  )
}
