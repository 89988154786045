import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export default function BreadImage(props) {

  const imageStyle = {
    float: 'right',
    margin: '10px',
    width: '33%',
    minWidth: '150px',
  }

  return (
    <StaticImage src="../images/year-in-review/2021/cropped/bread_1.jpg" alt="The best of many (not so great) loaves" className="yir-single-image" style={imageStyle} />
  )
}
